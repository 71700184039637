import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { auth } from "../../auth/firebaseAuth";
import firebase from "firebase/app";
import * as Yup from "yup";
import { baseUrl } from '../../utils/hosts';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import useStyles from "./common";
import CopyrightInfo from "./copyright";
import { locations } from "../../constants";
import axios from "axios";
import { setCurrentUser,getCurrentUser } from "../../utils/userDetails";
import { addNotification } from '../../components/libs/Notifications';

const domainUrl = `${baseUrl('REACT_APP_API_URL')}`;
const domain = new URL(domainUrl).hostname;

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid Email format!")
    .required("Email is required!"),
  password: Yup.string()
    .required("Password is required!")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Password should be minimum eight characters and have at least one uppercase letter, one lowercase letter, one number and one special character!"
    ),
});

export default function Login() {
  const [cLocation, setCLocation] = React.useState("");
  useEffect(() => {
    const cLoc = localStorage.getItem("LOCATION");
    locations.map((location) => {
      console.log(location.value);
      console.log(cLoc);
      if (cLoc === location.value) {
        setCLocation(location.value);
      }
    });
  }, []);
  const switchLocation = (target) => {
    setCLocation(target.target.value);
    localStorage.setItem("LOCATION", target.target.value);
    // window.location.reload();
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    email: "",
    password: "",
    rememberme: false,
    authenticated: false,
  });
  const currentUser = getCurrentUser();
  console.log('current');
    if(currentUser){
      setState({ ...state, authenticated: true });
      setTimeout(() => {
        window.location.href = "/frules";
      }, 1000);
    }
  const submitForm = (e) => {
    validationSchema
      .validate(state)
      .then(function (value) {
        console.log("Validation success!");
        login(e);
      })
      .catch(function (err) {
        console.log("Validation failed!");
        setState({ ...state, error: err.message });
      });
  };

  const signInWithEmailAndPassword = async({email, password, domain}) =>{
    try{
        const result = await axios.post(
          `${process.env.REACT_APP_AUTH_SERVICE_URL}/login`,
          {
            email,
            password,
            domain
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return result.data;
    }catch(e){
      console.log(e)
      return addNotification({
        title: `Error`,
        message: e.response?.data?.message || "Login failed",
        success: false,
      });
    }
  }

  const login = async(e) => {
    const currentUser = getCurrentUser();
    if(currentUser){
      setState({ ...state, authenticated: true });
      setTimeout(() => {
        window.location.href = "/frules";
      }, 1000);
    }else{
      let loginUser = await signInWithEmailAndPassword({email: state.email, password: state.password,domain:domain})
      if(loginUser.status){
        const verifyOtp = loginUser?.data?.message;
        if(!verifyOtp){
          setCurrentUser(loginUser.data)
            setTimeout(() => {
                window.location.href = "/frules";
            }, 1000);
        }else{
        localStorage.setItem("email", state.email);
        setState({ ...state, authenticated: true });
        setTimeout(() => {
                window.location.href = "/verify-otp";
              }, 1000);
      }
    }
  }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Please Login
        </Typography>

        {state.error ? (
          <>
            <br />
            <Alert severity="error">{state.error}</Alert>{" "}
          </>
        ) : (
          ""
        )}

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            onChange={(e) => setState({ ...state, email: e.target.value })}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="off"
            onChange={(e) => setState({ ...state, password: e.target.value })}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-select-small">Region</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              
              label="Age"
              onChange={switchLocation}
            >
              {locations &&
                locations.map((row, index) => {
                  const { label, value } = row;
                  return (
                    <MenuItem value={value} key={value}>
                     {label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitForm}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <CopyrightInfo />
      </Box>
    </Container>
  );
}
