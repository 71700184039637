import React from 'react';
import { CSVReader } from 'react-papaparse';

const buttonRef = React.createRef();

export default ({ setCsvData, setFile }) => {
  const cleanData = data => {
    if (data && data.length) {
      return data.filter(d => d.data && d.data.length > 1);
    }
    return [];
  };
  const handleOnDrop = (data, file) => {
    const cleanedData = cleanData(data);
    setCsvData(cleanedData);
    // if (buttonRef.current) {
    //   console.log({ ref: buttonRef.current.inputFileRef });
    // }
    setFile(file);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = data => {
    // console.log(data);
    setCsvData([]);
  };
  return (
    <>
      <CSVReader
        ref={buttonRef}
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        removeButtonColor="#659cef"
        onRemoveFile={handleOnRemoveFile}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
    </>
  );
};

export function removeFile(e) {
  if (buttonRef.current) {
    buttonRef.current.removeFile(e);
  }
}
