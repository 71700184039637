import React, { useState, useEffect, useRef } from "react";
import Header from "../../../components/libs/Header";
import { Grid, Box, Button } from "@material-ui/core";
import FlaggedQuotesTable from "./FlaggedQuotesTable";
import { flaggedQuotesCols } from "../../../constants";
import { fetchFlaggedQuotes } from "./actions";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const FlaggedQuotesList = (...routeConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [data, setData] = useState([]);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [count, setCount] = useState(0);
  const moment = require("moment");
  const [quoteCount, setQuoteCount] = useState(0);
  const [page, setPage] = useState(1);

  const handleToDate = (date) => {
    setToDate(date);
  };

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const getFlaggedQuotes = async () => {
    fetchFlaggedQuotes({ fromDate: fromDate, toDate: toDate, page }).then((res) => {
      const { list,  flag_count} = res;
      setData(list);
      setQuoteCount(flag_count)
    });
  };

  const handleExport = async () => {
    fetchFlaggedQuotes({ fromDate: fromDate, toDate: toDate, isExport: true, page });
  };

  const handleFilter = async () => {
    getFlaggedQuotes();
  };

  useEffect(() => {
    handleToDate(moment.utc(Date.now()).format("yyyy-MM-DDTHH:mm:00.000"));
    handleFromDate(
      moment
        .utc(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .format("yyyy-MM-DDTHH:mm:00.000")
    );
  }, []); 

  
  useEffect(() => {
    getFlaggedQuotes();
  }, [page]); 

  //   https://stackoverflow.com/a/56267719/3963153
  const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount && !count) {
      setCount (1);
      getFlaggedQuotes();
    } 
  }, [fromDate, toDate, page]);

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={5}>
        <Grid item md={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={4}>
              <h1>Flagged Quotes</h1>
            </Grid>
            <Grid item md={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="From Date"
                    value={
                      fromDate ||
                      moment
                        .utc(Date.now() - 7 * 24 * 60 * 60 * 1000)
                        .format("yyyy-MM-DDTHH:mm:00.000")
                    }
                    onChange={handleFromDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="To Date"
                    value={
                      toDate ||
                      moment.utc(Date.now()).format("yyyy-MM-DDTHH:mm:00.000")
                    }
                    onChange={handleToDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleFilter}
                fullWidth
              >
                Filter
              </Button>
            </Grid>
            <Grid item md={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleExport}
                fullWidth
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <FlaggedQuotesTable
          cols={flaggedQuotesCols}
          setShowModal={setShowModal}
          setModalData={setModalData}
          //   upsertRule={upsertRule}
          //   editRow={editRow}
          //   deleteRow={deleteRow}
          data={data}
          page={page}
          setPage={setPage}
          flagCount={quoteCount}
        />
      </Box>
    </>
  );
};

export default FlaggedQuotesList;
