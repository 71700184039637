import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TableCell, Fab, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { fraudCustomerTable } from "../../../constants";
import CustomerModal from "./customerModal";
import { useDispatch } from "react-redux";
import { deleteFraudCustomer } from "../../../store/customer/action";
import DeleteConfirmationModal from "./deleteConfirmationModal";
import moment from 'moment'

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

export default function CustomerRow({ row, sno }) {
  const classes = useRowStyles();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState();
  const [modalData, setModalData] = useState();
  const editRow = (data) => {
    setShowModal(true);
    setModalData(data);
  };

  const deleteRow = () => {
    dispatch(deleteFraudCustomer(deleteModal));
    setDeleteModal(null);
  };

  const openDeleteConfirmationModal = () => (
    <DeleteConfirmationModal
      onDelete={deleteRow}
      open={Boolean(deleteModal)}
      close={() => setDeleteModal(null)}
    />
  );

  const onClickDelete = (data) => {
    setDeleteModal(data.id);
   };

  const openModal = () => (
    <CustomerModal
      open={showModal}
      closeModal={() => setShowModal(false)}
      data={modalData}
    />
  );

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell component="th" scope="row" align="left">
          {sno}
        </TableCell>
        {fraudCustomerTable.map((el) => (
          <TableCell align="left" key={el.code}>
            { el.code === 'updated_at' ? moment(row[el.code]).format('LLL') : String(row[el.code])}
          </TableCell>
        ))}

        <TableCell component="th" scope="row" align="left">
          <Fab
            color="default"
            aria-label="edit"
            size="small"
            variant="extended"
            disableRipple={true}
            onClick={() => editRow(row)}
            className={classes.margin}
          >
            <EditIcon color="primary" />
          </Fab>
          <Fab
            color="default"
            aria-label="delete"
            size="small"
            variant="extended"
            disableRipple={true}
            onClick={() => onClickDelete(row)}
            className={classes.margin}
          >
            <DeleteIcon color="secondary" />
          </Fab>
        </TableCell>
      </StyledTableRow>
      {showModal ? openModal() : <></>}
      {deleteModal ? openDeleteConfirmationModal() : <></>}
    </React.Fragment>
  );
}
