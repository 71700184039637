export const fraudRulesCols = [
  { code: 'name', label: 'Rule Name' },
  { code: 'criteria', label: 'Criteria' },
  { code: 'enabled', label: 'Enabled' },
  { code: 'operator', label: 'Condition' },
  { code: 'input', label: 'Values' },
  { code: 'output', label: 'Penalty' },
  { code: 'updated_at', label: 'Last Updated At' },
  { code: 'updated_by', label: 'Last Updated By' },
  // { code: "tier", label: "Tier" },
];

export const flaggedQuotesCols = [
  { code: 'id', label: 'ID' },
  { code: 'customerId', label: 'Customer ID' },
  { code: 'customerEmail', label: 'Customer Email' },
  { code: 'markedOn', label: 'Marked On' },
  { code: 'rules', label: 'Rules' },
  // { code: "orderPlaced", label: "Order Placed" },
  // { code: "orderStatus", label: "Order Status" },
];

export const activityLogsCols = [
  { code: 'variant', label: 'Variant' },
  { code: 'key', label: 'Key' },
  { code: 'email', label: 'Email' },
  { code: 'name', label: 'Name' },
  { code: 'addedOn', label: 'Activity Time' },
  // { code: "before", label: "Before" },
  // { code: "after", label: "After" },
];

export const exceptionListCols = [
  { code: 'customerId', label: 'Customer Id' },
  { code: 'email', label: 'Customer Email' },
  { code: 'isActive', label: 'Is Active' },
  { code: 'updated_at', label: 'Last Updated At' },
  { code: 'updated_by', label: 'Last Updated By' },
];

export const msgHistoryCols = [
  { code: 'templateName', label: 'Template Name' },
  { code: 'updated_at', label: 'Last Updated At' },
  { code: 'csvLink', label: 'Csv Link' },
  { code: 'updated_by', label: 'Last Updated By' },
];

export const walletHistoryCols = [
  { code: 'updated_at', label: 'Uploaded at' },
  { code: 'csvLink', label: 'Uploaded file' },
  { code: 'updated_by', label: 'Uploaded by' },
  { code: 'jobId', label: 'Job ID' },
];

export const ratingsCols = [
  { code: 'customerId', label: 'Customer Id' },
  { code: 'customerName', label: 'Customer Name' },
  { code: 'customerEmail', label: 'Customer Email' },
  { code: 'orderId', label: 'Order Id' },
  { code: 'createdOn', label: 'Created On' },
  { code: 'updatedOn', label: 'Last Updated On' },
];

export const operatorMap = [
  { code: '==', label: 'equal to', numOfOperand: 1 },
  { code: '<=', label: 'less than equal to', numOfOperand: 1 },
  { code: '<', label: 'less than ', numOfOperand: 1 },
  { code: '>=', label: 'greater than equal to', numOfOperand: 1 },
  { code: '>', label: 'greater than', numOfOperand: 1 },
  { code: 'between', label: 'in between', numOfOperand: 2 },
];

export const criteriaMap = [
  { code: 'returnOrderCount', label: 'RTO/To Be Delivered Orders Count' },
];

export const penaltyMap = [
  { code: 'otpValidation', label: 'OTP Validation' },
  { code: 'restrictCod', label: 'Restrict COD' },
];

export const fraudCustomerTable = [
  { code: "email", label: "Email" },
  { code: "phone", label: "Phone" },
  { code: "forward", label: "Forward Fraud Status" },
  { code: "return", label: "Return Fraud Status" },
  { code: 'updated_at', label: 'Last Updated At' },
];

export const paymentMethodsGCC = [
  { id: "md_payfort", label: "Payfort" },
  { id: "cashondelivery", label: "COD" },
  { id: "apple_pay", label: "Apple Pay" },
  { id: "free", label: "Free" },
  { id: "tabby", label: "Tabby" },
  { id: "tamara", label: "Tamara" }
];

export const paymentMethodsIN = [
  { id: "cashondelivery", label: "COD" },
  { id: "free", label: "Free" },
  { id: "cashfree", label: "Cashfree" }
];

export const locations = [
  { label: 'GCC', value: 'GULF', key: 0 },
  { label: 'INDIA', value: 'IN', key: 1 }
];

export const FLAG =
  localStorage.getItem('LOCATION') === 'IN'
    ? 'https://storage.googleapis.com/dev-bucket.stylifashion.com/flags/flag_in.png'
    : 'https://storage.googleapis.com/dev-bucket.stylifashion.com/GCC_Flag.svg';

export const PODS = localStorage.getItem('LOCATION') === 'IN' ? 'IND' : 'GCC';
