import React from 'react';
import Menu from './menu';
import { useHistory } from 'react-router-dom';
import axiosMobileInstance from '../../../utils/axios';
import { addNotification } from '../Notifications';
import { getCurrentUser } from "../../../utils/userDetails";

const Header = ({ routeConfig }) => {
  const token = getCurrentUser();

  const history = useHistory();
  if (!token) history.replace("/login");

  /**
   * Intercepter to handle Unauthorized access globally
   */
  axiosMobileInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      return Promise.reject(err);
    }
  );

  const [route] = routeConfig;
  const { location } = route || {};
  const { pathname } = location || {};
  const menuOptions = [
    { label: "Fraudlent Rules", url: "/frules" },
    { label: "Customer Exception List", url: "/eList" },
    { label: "Flagged Quotes", url: "/fQuotes" },
    { label: "Activity Logs", url: "/activities" },
    { label: "Bulk Messaging", url: "/bMsg" },
    { label: "Messaging History", url: "/bmList" },
    { label: "Bulk Update Wallet", url: "/bUpdate" },
    { label: "Wallet Update History", url: "/bUpdateList" },
    { label: "Ratings", url: "/ratings" },
    { label: "Fraud Customers", url: "/customers" },
  ];

  const defaultMenuIndex = menuOptions.findIndex((el) => el.url === pathname);
  const handleTabClick = (index) => {
    history.push(menuOptions[index].url);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
      onClick: () => handleTabClick(index),
    };
  };
  return (
    <>
      <Menu
        a11yProps={a11yProps}
        menuOptions={menuOptions}
        handleTabClick={handleTabClick}
        defaultMenuIndex={defaultMenuIndex}
        history={history}
      />
    </>
  );
};

export default Header;
