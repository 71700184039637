import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  inputCenter: {
    textAlign: 'center'
  }
}));

const validationSchema = Yup.object({
  customerId: Yup.number().typeError(`Customer ID must be a number!`),
  email: Yup.string().required('Please enter customer Email!'),
  isActive: Yup.boolean()
});

const ExceptionModal = props => {
  const {
    modalData = {},
    upsertEList,
    showModal = false,
    setShowModal
  } = props;
  const { id, customerId, email, isActive } = modalData;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const formik = useFormik({
    initialValues: {
      id,
      customerId,
      email,
      isActive: isActive === undefined ? true : isActive
      // tier
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (values.customerId || values.email) upsertEList(values);
    }
  });

  const { values: formikVals } = formik;

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {/* <h1>Rule modal heading</h1> */}
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid item md={12}>
              <Grid container spacing={3} alignItems="center">
                <Grid item md={3}>
                  <TextField
                    id="id"
                    name="id"
                    type="hidden"
                    value={formikVals.id || -1}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    fullWidth
                    id="customerId"
                    name="customerId"
                    label="Customer Id "
                    variant="outlined"
                    margin="normal"
                    value={formik.values.customerId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.customerId &&
                      Boolean(formik.errors.customerId)
                    }
                    helperText={
                      formik.touched.customerId && formik.errors.customerId
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Customer Email"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.isActive}
                        name="isActive"
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="isActive"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={12}></Grid>
                <Grid item md={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item md={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid
                container
                spacing={3}
                display="flex"
                alignItems="center"
              ></Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ExceptionModal;
