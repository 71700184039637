import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@material-ui/core";

import Header from "../../../components/libs/Header";
import RuleTable from "./table";
import RuleModal from "./ruleModal";
import { fraudRulesCols } from "../../../constants/index";
import { saveRules, fetchRules, removeRule } from "../actions";
import AlertDialog from "../../../components/Dialog";
import moment from "moment";

const RuleList = (...routeConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [rulesData, setRulesData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [toDeleteIndex, setDeleteIndex] = useState(-1);
  const [dialogSelection, setDialogSelection] = useState(undefined);

  const processRules = (ruleList) => {
    ruleList.forEach((el) => {
      if (el.updated_at) el.updated_at = moment(el.updated_at).fromNow();
    });
    setRulesData(ruleList);
  };

  const getRules = async () => {
    fetchRules().then((res) => {
      const { ruleList } = res;
      processRules(ruleList || []);
    });
  };

  const upsertRule = async (ruleData) => {
    const { success, ruleList } = await saveRules({ ruleData });
    if (success) {
      setShowModal(false);
      processRules(ruleList || []);
    }
  };

  const deleteRule = async () => {
    const { success } = await removeRule({ ruleId: toDeleteIndex });
    if (success) {
      setDeleteIndex(-1);
      setDialogSelection(undefined);
      setDialogData({});
      getRules();
    }
  };

  const editRow = (e, rowData) => {
    setModalData(rowData);
    setShowModal(true);
  };

  const deleteRow = (e, rowData) => {
    // console.log({ rowData });
    setDialogData({
      heading: `Are you sure you want to delete ${rowData.name} `,
    });
    setShowDelete(true);
    setDeleteIndex(rowData.id);
  };

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    // console.log({ dialogSelection });
    if (dialogSelection) {
      deleteRule();
    }
  }, [dialogSelection]);

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={5}>
        <Grid item md={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={10}>
              <h1>Fraudulent Customer Rules</h1>
            </Grid>
            <Grid item md={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={editRow}
                fullWidth
              >
                Add New Rule
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <RuleTable
          fraudRulesCols={fraudRulesCols}
          setShowModal={setShowModal}
          setModalData={setModalData}
          upsertRule={upsertRule}
          editRow={editRow}
          deleteRow={deleteRow}
          rulesData={rulesData}
        />
        {showModal ? (
          <RuleModal
            modalData={modalData}
            showModal={showModal}
            upsertRule={upsertRule}
            setShowModal={setShowModal}
          />
        ) : (
          <></>
        )}
        <AlertDialog
          content={dialogData}
          setOpen={setShowDelete}
          open={showDelete}
          updateSelection={setDialogSelection}
        />
      </Box>
    </>
  );
};

export default RuleList;
