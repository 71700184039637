import React from 'react';

import 'react-notifications-component/dist/theme.css';
import ReactNotification, { store } from 'react-notifications-component';

export default () => {
  return <ReactNotification />;
};

export const addNotification = notificationProps => {
  const defaultNotificationProps = {
    insert: 'bottom',
    container: 'top-right'
  };
  if (notificationProps.success) {
    defaultNotificationProps.type = 'success';
    defaultNotificationProps.dismiss = {
      duration: 2000
    };
  } else {
    defaultNotificationProps.type = 'danger';
    defaultNotificationProps.dismiss = {
      duration: 2000
    };
  }
  store.addNotification({ ...defaultNotificationProps, ...notificationProps });
};
