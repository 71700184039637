import axios from '../../utils/axios';
import { isLoggedOut, logError, path } from '../../utils/index';
import { addNotification } from '../../components/libs/Notifications';
import { baseUrl } from '../../utils/hosts';
const { isEmpty } = require('lodash');
const errorMsg = 'You do not have adequate permission to access this Page. Please contact your administrator.';

export async function saveRules({ ruleData }) {
  const notificationProps = {
    title: `Update Rules`,
    message: '',
    success: false,
  };
  try {
    const requestBody = { ruleData };
    const saveResponse = await axios.post(
      `${baseUrl('REACT_APP_API_URL')}/saveRule`,
      requestBody
    );
    const success = path(['data', 'status'], saveResponse);
    const statusMsg = path(['data', 'statusMsg'], saveResponse);
    const ruleList = path(['data', 'response', 'rules'], saveResponse);

    notificationProps.message = success
      ? 'Rules update successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);

    return { success, ruleList };
  } catch (e) {
    notificationProps.message = `Error updating Rules : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
    return { success: false };
  }
}

export async function removeRule({ ruleId }) {
  const notificationProps = {
    title: `Delete Rules`,
    message: '',
    success: false,
  };
  try {
    const saveResponse = await axios.delete(
      `${baseUrl('REACT_APP_API_URL')}/deleteRule/${ruleId}`
    );
    const success = path(['data', 'status'], saveResponse);
    const statusMsg = path(['data', 'statusMsg'], saveResponse);

    notificationProps.message = success
      ? 'Rules Delete successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);

    return { success };
  } catch (e) {
    notificationProps.message = `Error deleting Rules : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
    return { success: false };
  }
}

export async function fetchRules() {
  const notificationProps = {
    title: `Fetch Rules`,
    message: '',
    success: false,
  };
  let ruleList = [];

  try {
    const fetchResponse = await axios.get(`${baseUrl('REACT_APP_API_URL')}/getRules`);
    const success = path(['data', 'status'], fetchResponse);
    const statusMsg = path(['data', 'statusMsg'], fetchResponse);
    ruleList = path(['data', 'response', 'rules'], fetchResponse);

    notificationProps.message = success
      ? 'Rules Fetched successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);
  } catch (e) {
    notificationProps.message = `Error fetching Rules : ${e.message}`;
    // if (isLoggedOut(e)) {
      // notificationProps.title = `Logged Out`;
      // notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
  }
  return { ruleList };
}

export async function saveException({ exceptionData }) {
  const notificationProps = {
    title: `Update Exception`,
    message: '',
    success: false,
  };
  try {
    const requestBody = exceptionData;
    const saveResponse = await axios.post(
      `${baseUrl('REACT_APP_API_URL')}/saveException`,
      requestBody
    );
    const success = path(['data', 'status'], saveResponse);
    const statusMsg = path(['data', 'statusMsg'], saveResponse);

    notificationProps.message = success
      ? 'Exception update successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);

    return { success };
  } catch (e) {
    notificationProps.message = `Error updating exceptions : ${e.message}`;
    showErrorMessage(notificationProps,e);
    logError(e);
    return { success: false };
  }
}

export async function fetchEList() {
  const notificationProps = {
    title: `Fetch Exception List`,
    message: '',
    success: false,
  };
  let eList = [];

  try {
    const fetchResponse = await axios.get(`${baseUrl('REACT_APP_API_URL')}/getElist`);

    console.log('fetchResponse', fetchResponse);

    const success = path(['data', 'status'], fetchResponse);
    const statusMsg = path(['data', 'statusMsg'], fetchResponse);
    eList = path(['data', 'response', 'eList'], fetchResponse);

    notificationProps.message = success
      ? 'Exception List Fetched successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);
  } catch (e) {
    notificationProps.message = `Error fetching Exceptio List : ${e.message}`;
    showErrorMessage(notificationProps,e);
    logError(e);
  }
  return eList;
}

export async function submitBulkMessaging({ file, fromPhone, templateName }) {
  const notificationProps = {
    title: `Bulk message`,
    message: '',
    success: false,
  };
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('templateName', templateName);

    const response = await axios.post(`${baseUrl('REACT_APP_API_URL')}/sendBulkMsg`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Content-Type': file.type
      },
    });
    const success = path(['data', 'status'], response);
    const statusMsg = path(['data', 'statusMsg'], response);
    const hasError = path(['data', 'hasError'], response);

    notificationProps.message = success
      ? `${
          hasError
            ? `Messages sent with some errors.Please refer history for details`
            : `Bulk Message sent .`
        }`
      : `${statusMsg}`;
    notificationProps.success = success && !hasError;
    addNotification(notificationProps);
    return success;
  } catch (e) {
    notificationProps.message = `Error sending Bulk message : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
    return false;
  }
}

export async function fetchWList() {
  const notificationProps = {
    title: `Fetch Message History`,
    message: '',
    success: false,
  };
  let mList = [];

  try {
    const fetchResponse = await axios.get(`${baseUrl('REACT_APP_API_URL')}/getWlist`);

    console.log('Message History', fetchResponse);

    const success = path(['data', 'status'], fetchResponse);
    const statusMsg = path(['data', 'statusMsg'], fetchResponse);
    mList = path(['data', 'response', 'mList'], fetchResponse);

    notificationProps.message = success
      ? 'Message History Fetched successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);
  } catch (e) {
    notificationProps.message = `Error fetching Message History : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
  }
  return mList;
}

export async function fetchMList() {
  const notificationProps = {
    title: `Fetch Message History`,
    message: '',
    success: false,
  };
  let mList = [];

  try {
    const fetchResponse = await axios.get(`${baseUrl('REACT_APP_API_URL')}/getMlist`);

    console.log('Message History', fetchResponse);

    const success = path(['data', 'status'], fetchResponse);
    const statusMsg = path(['data', 'statusMsg'], fetchResponse);
    mList = path(['data', 'response', 'mList'], fetchResponse);

    notificationProps.message = success
      ? 'Message History Fetched successfull .'
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);
  } catch (e) {
    notificationProps.message = `Error fetching Message History : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
  }
  return mList;
}

export async function fetchRatings(req) {
  const notificationProps = {
    title: `Fetch Ratings`,
    message: '',
    success: false,
  };
  let ratingsList = [];

  try {
    const fetchResponse = await axios.post(
      `${baseUrl('REACT_APP_API_URL')}/getRatings`,
      { searchCriteria: req },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    console.log(fetchResponse);
    const success = path(['data', 'status'], fetchResponse);
    const statusMsg = path(['data', 'statusMsg'], fetchResponse);
    ratingsList = path(['data', 'response'], fetchResponse);

    if (isEmpty(ratingsList)) {
      notificationProps.message = success
        ? 'No ratings found.'
        : `${statusMsg}`;
      notificationProps.success = false;
    } else {
      notificationProps.message = success
        ? 'Ratings Fetched successfull .'
        : `${statusMsg}`;
      notificationProps.success = success;
    }

    addNotification(notificationProps);
  } catch (e) {
    notificationProps.message = `Error fetching Ratings : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    showErrorMessage(notificationProps,e);
    logError(e);
  }
  return ratingsList;
}

const showErrorMessage = (notificationProps,err) => {
  if(err.response?.status === 403){
    notificationProps.message = errorMsg;
  }
  addNotification(notificationProps);
  return true;
};
