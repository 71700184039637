import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  Grid,
  TextField,
  Card,
  Typography,
  Switch,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import StyliMultiSelect from "../../../components/multiselect/StyliMultiSelect";
import { paymentMethodsGCC, paymentMethodsIN } from "../../../constants";
import { useDispatch } from "react-redux";
import { saveFraudCustomer } from "../../../store/customer/action";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputCenter: {
    textAlign: "center",
  },
  longText: {
    fontSize: 16,
    textOverflow: "ellipsis",
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    paddingLeft: "25px",
  },
}));

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email"),
  customer_id: Yup.number()
    .when(["return", "partial_return"], (a, b, schema) => {
      return a || b
        ? schema.required("Customer ID required")
        : schema.optional();
    })
    .positive("Enter Valid Customer ID"),
});

const CustomerModal = (props) => {
  const region = localStorage.getItem("LOCATION");
  const paymentMethods = region === "IN" ? paymentMethodsIN : paymentMethodsGCC;
  const { data = {}, open = false, closeModal } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = React.useState(getModalStyle);
  const [mutltiselectEl, setMutltiselectEl] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      id: data?.id ? data.id : "",
      email: data?.email ? data.email : "",
      phone: data?.phone ? data.phone : "",
      customer_id: data?.customer_id ? data.customer_id : "",
      blocked_payments: data?.blocked_payments ? data.blocked_payments : [],
      forward: data?.forward ? data.forward : false,
      return: data?.return ? data.return : false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(saveFraudCustomer(values));
      closeModal();
    },
  });

  const { values: formikVals } = formik;

  const openmultiselectmodal = (event) => {
    setMutltiselectEl(event.currentTarget);
  };

  const updateBlockedPayments = (val) => {
    formik.setFieldValue("blocked_payments", val);
    setMutltiselectEl(null);
  };
  const multiSelectModal = () => (
    <StyliMultiSelect
      openModal={mutltiselectEl}
      data={paymentMethods}
      label="blocked_payments"
      selected={
        data?.blocked_payments
          ? data?.blocked_payments
          : paymentMethods.map((m) => m.id)
      }
      closeModal={() => setMutltiselectEl(null)}
      closeAndUpdate={(d) => updateBlockedPayments(d)}
    />
  );

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Typography variant="body2">Manage Customer Details</Typography>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid item md={12}>
            <Grid container alignItems="center">
              <Grid item md={12}>
                <TextField
                  id="id"
                  name="id"
                  type="hidden"
                  value={formikVals.id || -1}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <Tooltip
                  title="Please use +xxx xxxxxxxx format."
                  placement="top"
                >
                  <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Tooltip>
              </Grid>

              <Grid container alignItems="center" spacing={2}>
                <Grid item md={4} xs={4} sm={4} lg={4}>
                  <TextField
                    fullWidth
                    id="customer_id"
                    name="customer_id"
                    label="Customer Id"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    value={formik.values.customer_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.customer_id &&
                      Boolean(formik.errors.customer_id)
                    }
                    helperText={
                      formik.touched.customer_id && formik.errors.customer_id
                    }
                  />
                </Grid>
                <Tooltip
                  title="Please select Order Type to block the customer."
                  placement="top"
                >
                  <Grid item md={8} xs={8} sm={8} lg={8}>
                    <FormControlLabel
                      id="forward"
                      name="forward"
                      label="Forward"
                      variant="outlined"
                      margin="normal"
                      value={formik.values.forward}
                      control={
                        <Switch
                          color="primary"
                          checked={formik.values.forward}
                        />
                      }
                      labelPlacement="end"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.forward && Boolean(formik.errors.forward)
                      }
                      helperText={
                        formik.touched.forward && formik.errors.forward
                      }
                    />
                    <FormControlLabel
                      id="return"
                      name="return"
                      label="Return"
                      variant="outlined"
                      margin="normal"
                      value={formik.values.return}
                      control={
                        <Switch
                          color="primary"
                          checked={formik.values.return}
                        />
                      }
                      labelPlacement="end"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.return && Boolean(formik.errors.return)
                      }
                      helperText={formik.touched.return && formik.errors.return}
                    />
                  </Grid>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card
                  style={{ maxHeight: "55px", cursor: "pointer" }}
                  onClick={(e) => openmultiselectmodal(e)}
                >
                  <span
                    style={{ marginLeft: "10px", color: "rgba(0, 0, 0, 0.54)" }}
                  >
                    Blocked Payments
                  </span>
                  <Typography
                    className={classes.longText}
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    {formik.values.blocked_payments?.length > 0
                      ? formik.values.blocked_payments?.map((pay) => {
                          const paymentLabel = paymentMethods.filter(
                            (p) => p.id === pay
                          );
                          return paymentLabel[0]?.label + ", ";
                        })
                      : "Please Select Payments to Block"}
                  </Typography>
                  <br />
                </Card>
                {multiSelectModal()}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={12}></Grid>
              <Grid item md={3}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={
                    formik.values.email === "" && formik.values.phone === ""
                  }
                >
                  Submit
                </Button>
              </Grid>
              <Grid item md={3}>
                <Button variant="contained" fullWidth onClick={closeModal}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid
              container
              spacing={3}
              display="flex"
              alignItems="center"
            ></Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default CustomerModal;
