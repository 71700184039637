import './App.css';
import React, {useEffect,useState} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Redirect
} from 'react-router-dom';
import Home from './containers/Home';
import Rules from './containers/Customer/Rules';
import ExceptionList from './containers/Customer/ExceptionList';
import FlaggedQuotesList from './containers/Customer/FlaggedQuotes';
import ActivityLogs from './containers/Customer/ActivityLogs';
import MsgHistory from './containers/Customer/MsgHistory';
import WalletHistory from './containers/Customer/WalletHistory';
import BulkSms from './containers/Customer/BulkSms';
import Ratings from './containers/Customer/Ratings';
import RatingsDetail from './containers/Customer/Ratings/ratingsDetail';
import Login from './components/Login';
import ForgotPassword from './components/Login/forgot-password';
import Startup from './Startup';
import CustomerBulkUpdate from './containers/Customer/BulkUpdate';
import { AppProvider } from './context/app';
import FraudCustomers from './containers/Customer/FraudCustomers/fraudCustomers';
import { getCurrentUser, setCurrentUser,removeFromCookie  } from "./utils/userDetails";
import axios from './utils/axios';
import VerifyOTP from './components/Login/verify-otp';

function App() {
  const refreshFirebaseToken = async () => {
    console.log('Refreshing Firebase Token...');
    try {
      const userData = getCurrentUser();
      const result = await axios.post(
        `${process.env.REACT_APP_AUTH_SERVICE_URL}/regenerate-token`,
        {
          refreshToken: userData?.refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.data.status) {
        const authUser = {
          uuid: result.data.data.uuid,
          name: result.data.data.displayName,
          email: result.data.data.email,
          emailVerified: result.data.data.registered,
          token: result.data.data.token,
          refreshToken: result.data.data.refreshToken,
        };
        setCurrentUser(authUser);
      } else {
        removeFromCookie();
        setCurrentUser();
      }
    } catch (error) {
      removeFromCookie();
      setCurrentUser();
    }
  };
  useEffect(() => {
    console.log('useEffect is triggered!');
    // Call refreshFirebaseToken when the component mounts
    refreshFirebaseToken();
    const intervalId = setInterval(() => {
      refreshFirebaseToken(); // Call the function here
    }, 10 * 60 * 1000);

    // Cleanup interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <AppProvider>
      <Router basename="/">
        <Startup>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/frules" exact component={Rules} />
            <Route path="/elist" exact component={ExceptionList} />
            <Route path="/login" exact component={Login} />
            <Route path="/verify-otp" exact component={VerifyOTP} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/fQuotes" exact component={FlaggedQuotesList} />
            <Route path="/activities" exact component={ActivityLogs} />
            <Route path="/bMsg" exact component={BulkSms} />
            <Route path="/bmList" exact component={MsgHistory} />
            <Route path="/bUpdate" exact component={CustomerBulkUpdate} />
            <Route path="/bUpdateList" exact component={WalletHistory} />
            <Route path="/ratings" exact component={Ratings} />
            <Route path="/ratingsDetail" exact component={RatingsDetail} />
            <Route path="/customers" exact component={FraudCustomers} />
          </Switch>
        </Startup>
      </Router>
    </AppProvider>
  );
}

export default App;
