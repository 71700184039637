import fileDownload from 'js-file-download';
import { addNotification } from '../components/libs/Notifications';
import axios from '../utils/axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export function logError(e) {
  console.error(e);
}

export const path = (p, o, ifnull = null) => {
  const reducerFunction = (xs, x) => {
    return xs && xs[x] ? xs[x] : ifnull;
  };
  return p.reduce(reducerFunction, o);
};

export const isLoggedOut = (e) => {
  if (e.response && e.response.status === 401) {
    localStorage.setItem('styli_sso_user', '');
    return true;
  }
  return false;
};

export const fileDownloadFromGCP = async ({ bucketName, bucketPath }) => {
  if (!bucketPath) {
    return addNotification({
      title: `Error`,
      message: 'Path not available',
      success: false,
    });
  }

  const fileNameSplit = bucketPath.split('/');
  const localFilename = fileNameSplit[fileNameSplit.length - 1];
  addNotification({
    title: `File download initiated!`,
    message: 'Kindly wait for a while for it to finish.',
    success: true,
  });

  try {
    const result = await axios.post(
      `${apiBaseUrl}/download/file`,
      { file: bucketPath },
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/zip',
        },
      }
    );
    console.log('csv data debug', result.data);
    fileDownload(result.data, localFilename.replace('.csv', '.zip'));
  } catch (e) {
    console.log('error ' + e.message);
    const msg = 'You do not have adequate permission to access this Page. Please contact your administrator.';
    
    return addNotification({
      title: `Error`,
      message: e.response?.status === 403 ? msg : e.message,
      success: false,
    });
  }
};
