import React, { useState, useEffect } from "react";
import Header from "../../../components/libs/Header";
import { Grid, Box, Button } from "@material-ui/core";
import { activityLogsCols } from "../../../constants";
import { fetchActivityLogs } from "./actions";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ActivityLogsTable from "./ActivityLogsTable";
import moment from "moment";

const ActivityLogs = (...routeConfig) => {
  const [data, setData] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [page, setPage] = useState(1);

  const handleToDate = (date) => {
    setToDate(date);
  };

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const processActivityLogs = (list, activity_count) => {
    list.forEach((el) => {
      if (el.addedOn) el.addedOn = moment(el.addedOn).fromNow();
    });
    setData(list);
    setActivityCount(activity_count);
  };

  const getActivityLogs = async () => {
    fetchActivityLogs({ fromDate: fromDate, toDate: toDate, page: page }).then((res) => {
      const { list, activity_count } = res;
      processActivityLogs(list || [], activity_count);
    });
  };

  const handleExport = async () => {
    fetchActivityLogs({ fromDate: fromDate, toDate: toDate, isExport: true });
  };

  useEffect(() => {
    getActivityLogs();
  }, [page]);

//   useEffect(() => {
//     getActivityLogs();
//   }, [fromDate, toDate]);

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={5}>
        <Grid item md={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={5}>
              <h1>Activity Logs</h1>
            </Grid>
            {/* <Grid item md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="From Date"
                    value={fromDate || null}
                    onChange={handleFromDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="To Date"
                    value={toDate || null}
                    onChange={handleToDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid> */}
            {/* <Grid item md={1}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleExport}
                fullWidth
              >
                Export
              </Button>
            </Grid> */}
          </Grid>
        </Grid>

        <ActivityLogsTable
          cols={activityLogsCols}
          setShowModal={setShowModal}
          setModalData={setModalData}
          //   upsertRule={upsertRule}
          //   editRow={editRow}
          //   deleteRow={deleteRow}
          data={data}
          page={page}
          setPage={setPage}
          count={activityCount}
        />
      </Box>
    </>
  );
};

export default ActivityLogs;
