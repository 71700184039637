import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { auth } from '../../auth/firebaseAuth';
import useStyles from './common';
import CopyrightInfo from './copyright';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid Email format!')
    .required('Email is required!'),
});

export default function ForgotPassword() {
  const classes = useStyles();

  const [state, setState] = React.useState({ email: '' });

  const submitForm = (e) => {
    validationSchema
      .validate(state)
      .then(function (value) {
        console.log('Validation success!');
        resetPassword(e);
      })
      .catch(function (err) {
        console.log('Validation failed!');
        setState({ ...state, error: err.message });
      });
  };

  const resetPassword = (e) => {
    e.preventDefault();
    auth
      .sendPasswordResetEmail(state.email)
      .then(() => {
        setState({ ...state, success: 'Successfully sent!', error: '' });
      })
      .catch((err) => {
        setState({ ...state, error: err.message });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        {state.error && (
          <>
            <br />
            <Alert severity="error">{state.error}</Alert>
          </>
        )}

        {state.success && (
          <>
            <br />
            <Alert severity="success">{state.success}</Alert>
          </>
        )}

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            onChange={(e) => setState({ ...state, email: e.target.value })}
            autoFocus
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitForm}
          >
            Submit
          </Button>
          {/* history.replace("/login"); */}

          <Grid container>
            <Grid item xs>
              <Link to="/login" variant="body2">
                Go to login page.
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <CopyrightInfo />
      </Box>
    </Container>
  );
}
