import React from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import ExceptionListStyles from "../styleComponent";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

export default function Row({ orderId, row, sno, ratingsCols, ratingsList }) {
  const classes = ExceptionListStyles();
  const history = useHistory();
  const id = orderId;

  const getRatingDetails = () => {
    history.push(`/ratingsDetail?orderId=${id}`, { ratings: ratingsList });
  };

  return (
    <React.Fragment>
      <StyledTableRow
        className={classes.root}
        onClick={() => getRatingDetails()}
      >
        <TableCell component="th" scope="row" align="left">
          {sno}
        </TableCell>
        {ratingsCols.map((el) => {
          return (
            <TableCell align="left" key={el.code}>
              {row[el.code] && String(row[el.code])}
            </TableCell>
          );
        })}
      </StyledTableRow>
    </React.Fragment>
  );
}
