import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import JsonDiffReact from "jsondiffpatch-for-react";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

export default function ActivityLogsTableRow({
  row,
  sno,
  cols
}) {
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell component="th" scope="row" align="left">
          {sno}
        </TableCell>
        {cols.map((el) => {
          return (
            <TableCell align="left" key={el.code}>
              {row[el.code] && String(row[el.code])}
            </TableCell>
          );
        })}
        <TableCell component="th" scope="row" align="left">
          <JsonDiffReact left={row.before} right={row.after} />
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
