import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Row from "./tableRow";

const useRowStyles = makeStyles((theme) => ({
  root: {},
  headCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[400],
  },
}));

const ExceptionsTable = ({
  exceptionListCols = [],
  editRow,
  eListData,
  deleteRow,
}) => {
  const classes = useRowStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell} align="left">
              S No.
            </TableCell>
            {exceptionListCols.map((element, index) => {
              return (
                <TableCell
                  className={classes.headCell}
                  align="left"
                  key={index}
                >
                  {element.label}
                </TableCell>
              );
            })}
            <TableCell className={classes.headCell}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eListData.map((row, index) => {
            const { input1, input2 } = row;
            return (
              <Row
                key={row.name}
                sno={index + 1}
                row={{
                  ...row,
                  input: `${input1} ${input2 ? `, ${input2}` : ""}`,
                }}
                exceptionListCols={exceptionListCols}
                editRow={editRow}
                deleteRow={deleteRow}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExceptionsTable;
