import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import FlaggedQuotesTableRow from "./FlaggedQuotesTableRow";
import Pagination from "@material-ui/lab/Pagination";

const useRowStyles = makeStyles((theme) => ({
  root: {},
  headCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[400],
  },
}));

const FlaggedQuotesTable = ({ cols = [], data, page, setPage, flagCount }) => {
  const classes = useRowStyles();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell} align="left">
              S No.
            </TableCell>
            {cols.map((element, index) => {
              return (
                <TableCell
                  className={classes.headCell}
                  align="left"
                  key={index}
                >
                  {element.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row, index) => {
              const { input1, input2 } = row;
              return (
                <FlaggedQuotesTableRow
                  key={row.name}
                  sno={index + 1}
                  row={{
                    ...row,
                    input: `${input1} ${input2 ? `, ${input2}` : ""}`,
                  }}
                  cols={cols}
                />
              );
            })}
        </TableBody>
      </Table>
      <br />
        <Pagination
          count={Math.ceil(flagCount / 100)}
          page={page}
          onChange={(e, v) => setPage(v)}
          color="primary"
        />
        <br />
    </TableContainer>
  );
};

export default FlaggedQuotesTable;
