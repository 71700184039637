import React, { useState, useEffect } from 'react';
import { CSVDownloader } from 'react-papaparse';
import LinkSharpIcon from '@material-ui/icons/LinkSharp';

import Header from '../../../components/libs/Header';
import Loader from '../../../components/Loader';
import CsvLib, { removeFile } from '../../../components/CsvLib';
// import CsvPreview from './csvPreview';
import { Box, Button, Grid, TextField, Fab } from '@material-ui/core';
import { submitBulkUpdate } from './actions';

const CustomerBulkUpdate = (...routeConfig) => {
  const [showLoader, setShowLoader] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [file, setFile] = useState();

  const submitData = async () => {
    setShowLoader(true);

    console.log('file', file);

    const response = await submitBulkUpdate({ file });
    if (response) {
      setFile();
      removeFile();
    }
    setShowLoader(false);
  };

  useEffect(() => {
    // setHasError(false);
    // setColError(false);
    // setRowCount(0);
    if (csvData && csvData.length) {
      const [headData, ...bodyData] = csvData;
      //   console.log({ bodyData });
      //   setRowCount(bodyData.length);
      const headers = (headData && headData.data) || [];
      if (headers.length) {
        // console.log({ headers });
        // if (headers[0] !== 'phone' || headers[1] !== 'isArabic') {
        //   setColError(true);
        // }
      }
    }
  }, [csvData]);

  // console.log({ templateName, varNum });
  return (
    <>
      <Header routeConfig={routeConfig} />
      <>
        <Box m={8}>
          <Grid item md={12} container spacing={3} alignItems="center">
            <Grid item md={8}>
              <h1>Bulk Update Wallet </h1>
            </Grid>
            {/* <Grid item md={4}>
            <a
              href="https://landmarkgroup-my.sharepoint.com/:w:/p/abhimanyu_srivastava/EdZKYJuJi4JGgj0mI_O0DOQB_SD0YQWfw5VR8tuJhDn2Mg?e=JnsfxQ"
              target="_blank"
            >
              Message template details
              <Fab
                color="default"
                aria-label="delete"
                size="small"
                variant="extended"
                disableRipple={true}
                // onClick={e => deleteRow(e, row)}
                // className={classes.margin}
              >
                <LinkSharpIcon color="primary" />
              </Fab>
            </a>
          </Grid> */}
          </Grid>
          {/* <h2>Create CSV </h2> */}

          {/* <Grid item md={12} container spacing={3} alignItems="center">
          <Grid item md={4}>
            <TextField
              //   id="standard-full-width"
              id="outlined-basic-1"
              label="Template Name"
              style={{ margin: 8 }}
              //   placeholder="Template Name"
              helperText="Enter the name of template as defined in Freshchat"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={templateName}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              //   id="standard-full-width"
              type="number"
              id="outlined-basic-2"
              label="No. of Variables"
              style={{ margin: 8 }}
              //   placeholder="Template Name"
              helperText="Enter the number of variables for template"
              variant="outlined"
              onChange={(e) => {
                setVarNum(e.target.value);
              }}
              value={varNum}
            />
          </Grid>
          <Grid item md={3}>
            {templateName && varNum ? (
              <CSVDownloader
                data={sampleData}
                //   type="button"
                type={'link'}
                filename={templateName}
                bom={true}
              >
                <Button variant="contained" color="primary">
                  Download CSV
                </Button>
              </CSVDownloader>
            ) : (
              ''
            )}
          </Grid>
        </Grid> */}
          <Grid item md={12}>
            <h2>Upload CSV</h2>
          </Grid>

          <Grid item md={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={5}>
                <CsvLib setCsvData={setCsvData} setFile={setFile} />
              </Grid>
              {csvData.length ? (
                <>
                  <Grid item md={3}>
                    {/* <TextField
                    //   id="standard-full-width"
                    id="outlined-basic-3"
                    label="From Phone number"
                    style={{ margin: 8 }}
                    //   placeholder="Template Name"
                    // helperText="Enter from phone number"
                    variant="outlined"
                    // onChange={e => {
                    //   setFromPhone(e.target.value);
                    // }}
                    value={fromPhone}
                    disabled
                  /> */}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    container
                    // alignContent="flex-end"
                    // justify="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitData}
                      // disabled={csvHasError || colError}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          {/* {rowCount < 300 ? (
          <CsvPreview
            csvData={csvData}
            setHasError={setHasError}
            colError={colError}
          />
        ) : (
          <h3>
            Number of rows cannot be more than 300. Please create batches to
            proceed.
          </h3>
        )} */}
        </Box>
        <Loader showLoader={showLoader} />
      </>
    </>
  );
};

export default CustomerBulkUpdate;
