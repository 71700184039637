import {
    Avatar,
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import useStyles from './common';
import CopyrightInfo from './copyright';
import axios from "axios";
import { addNotification } from '../libs/Notifications';
import { setCurrentUser } from '../../utils/userDetails';
import { baseUrl } from '../../utils/hosts';

const domainUrl = `${baseUrl('REACT_APP_API_URL')}`;
const domain = new URL(domainUrl).hostname;

const validationSchema = Yup.object({
    otp: Yup.string()
        .required('OTP is required!'),
});

export default function VerifyOTP() {
    const classes = useStyles();

    const [state, setState] = React.useState({ otp: '' });

    const submitForm = async (e) => {
        e.preventDefault();
        validationSchema
            .validate(state)
            .then(function (value) {
                console.log('Validation success!');
                verifyOTP(state.otp);

            })
            .catch(function (err) {
                console.log('Validation failed!');
                setState({ ...state, error: err.message });
            });
    };

    const verifyOTP = async (otp) => {
        try {
            const email = window.localStorage.getItem('email')
            const result = await axios.post(
                `${process.env.REACT_APP_AUTH_SERVICE_URL}/verify-otp`,
                {
                    otp, email,domain
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if(result?.data?.status){
            const itemData = result?.data?.data?.userData;
            setCurrentUser(itemData)
            window.localStorage.removeItem('email');
            setTimeout(() => {
                window.location.href = "/frules";
            }, 1000);
        }else{
            return addNotification({
                title: `Error`,
                message:  "OTP validation failed",
                success: false,
            });
        }
        } catch (e) {
            console.log(e)
            return addNotification({
                title: `Error`,
                message: e.response?.data?.message || "OTP validation failed",
                success: false,
            });
        }
    };

    useEffect(() => {
        const loginData = localStorage.getItem('email');
        if (!loginData) {
            window.location.href = "/login";

        }
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Verify OTP
                </Typography>

                {state.error && (
                    <>
                        <br />
                        <Alert severity="error">{state.error}</Alert>
                    </>
                )}

                {state.success && (
                    <>
                        <br />
                        <Alert severity="success">{state.success}</Alert>
                    </>
                )}

                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="otp"
                        label="Enter OTP"
                        name="otp"
                        autoComplete="off"
                        onChange={(e) => setState({ ...state, otp: e.target.value })}
                        autoFocus
                    />

                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={submitForm}
                    >
                        Submit
                    </Button>
                    {/* history.replace("/login"); */}

                    <Grid container>
                        <Grid item xs>
                            <Link to="/login" variant="body2">
                                Go to login page.
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <CopyrightInfo />
            </Box>
        </Container>
    );
}
