import React from "react";
import msgHistoryStyle from "../styleComponent";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Row from "./tableRow";
import { fileDownloadFromGCP } from '../../../utils';

const ExceptionsTable = ({
  msgHistoryCols = [],
  editRow,
  mListData,
  deleteRow,
}) => {
  const classes = msgHistoryStyle();

  const handleFileDownload = (bucketName, bucketPath) => {
    fileDownloadFromGCP({ bucketName, bucketPath });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell} align="left">
              S No.
            </TableCell>
            {msgHistoryCols.map((element, index) => {
              return (
                <TableCell
                  className={classes.headCell}
                  align="left"
                  key={index}
                >
                  {element.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {mListData.map((row, index) => {
            const { input1, input2 } = row;
            return (
              <Row
                handleFileDownload={handleFileDownload}
                key={row.name}
                sno={index + 1}
                row={{
                  ...row,
                  input: `${input1} ${input2 ? `, ${input2}` : ""}`
                }}
                msgHistoryCols={msgHistoryCols}
                editRow={editRow}
                deleteRow={deleteRow}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExceptionsTable;
