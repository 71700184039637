import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';
import {
  Typography,
  Grid,
  Toolbar,
  AppBar,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  IconButton,
  Chip,
  Avatar
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FLAG, PODS } from '../../../constants';
import axios from 'axios';
import { getCurrentUser,removeFromCookie } from "../../../utils/userDetails";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

export default function ScrollableTabsButtonAuto({
  a11yProps,
  menuOptions,
  defaultMenuIndex = -1,
  history
  // handleTabClick
}) {
  const classes = useStyles();
  const [value, setValue] = useState(defaultMenuIndex);
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const logOut = async () => {
    const user = getCurrentUser();
    removeFromCookie();
    // localStorage.removeItem('styli_sso_user');
    localStorage.removeItem('region');
    localStorage.clear();
    if(user){
    const {uuid, token } = user;
    await axios.post(
      `${process.env.REACT_APP_AUTH_SERVICE_URL}/logout`,
      {
        uuid
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.trim(),
        }
      }
    );
    }
    history.push('/login');
  };

  // const handleChange = event => {
  //   setAuth(event.target.checked);
  // };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Grid container display="flex">
            <Grid item md={2}>
              <h3>Customer Management</h3>
              
            </Grid>
            <Grid item md={9}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {menuOptions.map((mI, index) => {
                  return (
                    <Tab
                      label={mI.label}
                      {...a11yProps(index)}
                      style={{ textTransform: 'none' }}
                      key={index}
                      // onClick={() => handleTabClick(index)}
                    />
                  );
                })}
                {/* <Tab label="Item One" {...a11yProps(0)} />
                <Tab label="Item Two" {...a11yProps(1)} />
                <Tab label="Item Three" {...a11yProps(2)} /> */}
              </Tabs>
            </Grid>
            <Grid item md={1}>
              {auth && (
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={logOut}>Log Out</MenuItem>
                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                  </Menu>
                  <Chip
                    avatar={<Avatar alt="Natacha" src={FLAG} />}
                    label={PODS}
                    variant="outlined"
                  />
                </div>
              )}
              
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        {value}
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
  );
}
