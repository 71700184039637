import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';

import Header from '../../../components/libs/Header';
import MListTable from './table';
import { walletHistoryCols } from '../../../constants/index';
import { fetchWList } from '../actions';
import moment from 'moment';

const ExceptionList = (...routeConfig) => {
  const [mListData, setMListData] = useState([]);

  const getMList = async () => {
    fetchWList().then((res) => {
      console.log({ res });
      res.map((el) => {
        if (el.updated_at) el.updated_at = moment(el.updated_at).fromNow();
      });
      setMListData(res);
    });
  };

  useEffect(() => {
    getMList();
  }, []);

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={5}>
        <Grid item md={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={10}>
              <h1>Bulk Wallet Update History</h1>
            </Grid>
          </Grid>
        </Grid>
        <MListTable msgHistoryCols={walletHistoryCols} mListData={mListData} />
      </Box>
    </>
  );
};

export default ExceptionList;
