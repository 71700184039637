import * as React from "react";
import {
  Button,
  Checkbox,
  Container,
  Grid,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textFormat: {
    textTransform: "initial",
  },
}));
export default function StyliMultiSelect(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(props.selected);

  const handleChange = (status) => {
    if (selected.includes(status.id)) {
      selected.splice(selected.indexOf(status.id), 1);
      setSelected(Object.assign([], selected));
    } else {
      setSelected(selected.concat(status.id));
    }
  };
  const selectAll = () => {
    if (selected.length !== props.data?.length) {
      setSelected(props.data.map((d) => d.id));
    } else {
      setSelected([]);
    }
  };

  const closeAndUpdate = () => {
    props.closeAndUpdate(selected);
    props.closeModal();
  }

  return (
    <Menu
      id="simple-menu"
      anchorEl={props.openModal}
      keepMounted
      open={Boolean(props.openModal)}
      onClose={closeAndUpdate}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.textFormat}
              onClick={selectAll}
            >
              {selected.length === props.data.length
                ? "Un-Select All"
                : "Select All"}
            </Button>
          </Grid>
          
          <Grid item>
            <Button
              variant="outlined"
              className={classes.textFormat}
              onClick={closeAndUpdate}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
      <div style={{ maxHeight: "400px", overflow: "auto" }}>
        {props.data.map((status) => (
          <MenuItem
            value={status.id}
            key={status.id}
            onChange={() => handleChange(status)}
          >
            <Checkbox checked={selected.indexOf(status.id) > -1} />
            <ListItemText primary={status.label} />
          </MenuItem>
        ))}
      </div>
    </Menu>
  );
}
