import React, { useState } from "react";
import { Grid, Box, Button, TextField } from "@material-ui/core";

import Header from "../../../components/libs/Header";
import RatingsTable from "./table";
import { ratingsCols } from "../../../constants/index";
import { fetchRatings } from "../actions";

const Ratings = (...routeConfig) => {

    const [ratingsList, setRatingsList] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');

    const searchRatings = () => {
        fetchRatings(searchCriteria).then((res) => {
            setRatingsList(res);
        });
    }

    return (
        <>
            <Header routeConfig={routeConfig} />
            <Box p={5}>
                <Grid item md={12}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item md={5}>
                            <h1>Search Ratings</h1>
                        </Grid>
                        <Grid item md={4}>
                            <Grid>
                                <TextField
                                    id="outlined-basic-1"
                                    label="Search"
                                    style={{ margin: 8 }}
                                    helperText="Order No. / Customer Id / Email"
                                    fullWidth
                                    variant="outlined"
                                    onChange={e => {
                                        setSearchCriteria(e.target.value);
                                    }}
                                    value={searchCriteria}
                                />
                            </Grid>
                        </Grid>

                        <Grid item md={2}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={searchRatings}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <RatingsTable
                    ratingsCols={ratingsCols}
                    ratingsList={ratingsList}
                />

            </Box>
        </>
    );
};

export default Ratings;
