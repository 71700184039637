import React, { createContext, useContext, useReducer } from "react";

import TrackoReducer, {
  initialState as trackoInitialState
} from "../containers/Home/reducer";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider
      value={{ tracko: useReducer(TrackoReducer, trackoInitialState) }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useStateValue = () => useContext(AppContext);
