import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, Grid, Typography } from "@material-ui/core";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputCenter: {
    textAlign: "center",
  },
  longText: {
    fontSize: 16,
    textOverflow: "ellipsis",
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    paddingLeft: "25px",
  },
}));

const DeleteConfirmationModal = (props) => {
  const { onDelete, open = false, close } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Typography variant="body2">
          Are you sure to remove this customer?
        </Typography>

        <Grid item md={12}>
          <Grid container spacing={3}>
            <Grid item md={12}></Grid>
            <Grid item md={6}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                onClick={onDelete}
              >
                Yes
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button variant="contained" fullWidth onClick={close}>
                No
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
