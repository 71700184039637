import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, TableCell, TableRow } from '@material-ui/core';
import ExceptionListStyles from "../styleComponent";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

export default function Row({ row, sno, msgHistoryCols, handleFileDownload }) {
  const classes = ExceptionListStyles();

  const getColValue = (colCode, row) => {
    if (colCode === "csvLink") {
      const { bucketName, bucketPath } = row;
      return (
        // <a href={`https://storage.googleapis.com/${bucketName}/${bucketPath}`}>
        //   Download CSV
        // </a>
        <Button
          type="button"
          variant="text"
          color="primary"
          className={classes.submit}
          onClick={(e) => handleFileDownload(bucketName, bucketPath)}
        >
          Download
        </Button>
      );
    }
  };

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell component="th" scope="row" align="left">
          {sno}
        </TableCell>
        {msgHistoryCols.map((el) => {
          return (
            <TableCell align="left" key={el.code}>
              {row[el.code] === undefined
                ? getColValue(el.code, row)
                : String(row[el.code])}
            </TableCell>
          );
        })}
      </StyledTableRow>
    </React.Fragment>
  );
}
