import * as ActionTypes from "./actionTypes";
import axios from "../../utils/axios";
import { addNotification } from "../../components/libs/Notifications";
import { baseUrl } from "../../utils/hosts";


const loadCust = (data) => {
  return {
    type: ActionTypes.LOAD_FRAUD_CUSTOMERS,
    value: data,
  };
};
const accessNotification = {
  title: `permission denied`,
  message: 'You do not have adequate permission to access this Page. Please contact your administrator.',
  success: false,
};
export const loadFraudCustomers = (payload) => {
  const reqData ={searchFor:payload?.searchFor,offset: payload?.page ? (payload?.page - 1) * 10 : 0,limit: 10};
  return (dispatch) => {
    axios
      .post(`${baseUrl('REACT_APP_API_URL')}/fraud/customer/list`, reqData)
      .then((res) => {
        dispatch(loadCust(res.data.response));
        addNotification({
          success: true,
          message: `Customer Details Fetched`,
        });
      })
      .catch((err) => {
        checkUnauthorized(err);
        console.log(err);
      });
  };
};

export const saveFraudCustomer = (payload) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl('REACT_APP_API_URL')}/fraud/customer/save`, payload)
      .then((res) => {
        if (res.status === 200) dispatch(loadFraudCustomers(null));
        addNotification({
          success: true,
          message: `Customer Details Saved`,
        });
      })
      .catch((err) => {
        checkUnauthorized(err);
        console.log(err);
      });
  };
};

export const deleteFraudCustomer = (id) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl('REACT_APP_API_URL')}/fraud/customer/${id}`)
      .then((res) => {
        if (res.status === 200) dispatch(loadFraudCustomers(null));
        addNotification({
          success: true,
          message: `Customer Details Deleted`,
        });
      })
      .catch((err) => {
        checkUnauthorized(err);
        console.log(err);
      });
  };
};


const checkUnauthorized = (err) => {
  if (err.response?.status === 401) {
    addNotification({
      title: `Logged Out`,
      success: false,
      message: `Please login again`,
    });
  }else if(err.response?.status === 403){
    addNotification(accessNotification);
  }
  return true;
};


export const uploadFraudCustomer = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return (dispatch) => {
    axios
      .post(`${baseUrl('REACT_APP_API_URL')}/fraud/customer/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          addNotification({
            title: `Uploaded Success`,
            success: true,
            message: `Provided details has been processed.`,
          });
          dispatch(loadFraudCustomers(null));
          dispatch(setUploadSummary(res.data.response));
        }
        return { completed: true };
      })
      .catch((err) => {
        addNotification({
          title: `Uploaded Error`,
          success: false,
          message: `There is an error in uploading. Error : ${err.message}`,
        });
        return { completed: true };
      });
  };
};

const setUploadSummary = (data) => {
  return {
    type: ActionTypes.UPLOAD_SUMMARY,
    value: data,
  };
};

export const resetUploadSummary = () => {
  return {
    type: ActionTypes.RESET_UPLOAD_SUMMARY,
    value: null,
  };
};


export const downloadCustomers = () => {
  return (dispatch) => {
    axios
      .get(`${baseUrl('REACT_APP_API_URL')}/fraud/customer/download`)
      .then((res) => {
        addNotification({
          success: true,
          message: `Customer Details Downloaded`,
        });
        const file = res.data;
        const blob = new Blob([file], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("hidden", true);
        a.setAttribute("href", url);
        a.setAttribute("download", "fraud_customer_template.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        addNotification({
          title: `Download Error`,
          success: false,
          message: `There is an error in downloading. Error : ${err.message}`,
        });
      });
  };
};