import axios from 'axios';
import { getCurrentUser } from "../utils/userDetails";

// const email = getEmail();
const axiosOptions = {
  timeout: 120000,
  headers: {}
};
const axiosMobileInstance = axios.create(axiosOptions);
axiosMobileInstance.interceptors.request.use(
  async config => {
    const user = getCurrentUser() || null;
    let token;
    if (user) {
      token = user.token;
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['X-Client-Version'] =  process.env.TAG_NAME || process.env.BRANCH || 'development';
    config.headers['region']= localStorage.getItem('LOCATION');
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosMobileInstance;
