import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import CustomerRow from "./tableRow";
import { fraudCustomerTable } from "../../../constants";
import Pagination from "@material-ui/lab/Pagination";

const useRowStyles = makeStyles((theme) => ({
  root: {},
  headCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[400],
  },
}));

const CustomerTable = ({ setPage=()=>{},page=1}) => {
  const classes = useRowStyles();
  
  const customerList = useSelector((state) => state.fraud.customers);
  const total_customer = useSelector(
    (state) => state.fraud.total_customers
  );
  return (
    <>
     <Paper>
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell} align="left">
              S No.
            </TableCell>
            {fraudCustomerTable.map((element, index) => {
              return (
                <TableCell
                  className={classes.headCell}
                  align="left"
                  key={index}
                >
                  {element.label}
                </TableCell>
              );
            })}
            <TableCell className={classes.headCell}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerList &&
            customerList.map((row, index) => {
              return <CustomerRow sno={index + 1} row={row} key={row.id} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <br />
        <Pagination
          count={Math.ceil(total_customer / 10)}
          page={page}
          onChange={(e, v) => setPage(v)}
          color="primary"
        />
        <br />
      </Paper>
        </>
  );
};

export default CustomerTable;
