import axios from "../../../utils/axios";
import { isLoggedOut, logError, path } from "../../../utils/index";
import { addNotification } from "../../../components/libs/Notifications";
import fileDownload from 'js-file-download';
import { baseUrl } from "../../../utils/hosts";


export async function fetchFlaggedQuotes({ fromDate, toDate, isExport, page }) {
  const notificationProps = {
    title: `Fetch Flagged Quotes`,
    message: "",
    success: false,
  };
  let list = [];
  let flag_count = 0;
  try {
    const requestBody = { fromDate: fromDate, toDate: toDate, isExport, page };
    const fetchResponse = await axios.post(
      `${baseUrl('REACT_APP_API_URL')}/getFlaggedQuotes`,
      requestBody,
      {
        responseType: !isExport ? undefined : "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: isExport ? "text/csv" : "application/json",
        },
      }
    );

    if (!isExport) {
    const success = path(["data", "status"], fetchResponse);
    const statusMsg = path(["data", "statusMsg"], fetchResponse);
    list = path(["data", "response", "flaggedQuotes"], fetchResponse);
    list = path(["data", "response", "flaggedQuotes", "rules"], fetchResponse);
    flag_count = path(["data", "response", "flaggedQuotes", "countFlagQuote"], fetchResponse);
    
    notificationProps.message = success
      ? "Flagged Quotes Fetched successfully!"
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);
    } else {
        fileDownload(fetchResponse.data, 'flaggedQuotes.csv');
    }
  } catch (e) {
    notificationProps.message = `Error fetching Flagged Quotes : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    if(e.response?.status === 403){
      notificationProps.message = 'You do not have adequate permission to access this Page. Please contact your administrator.';
    }
    addNotification(notificationProps);
    logError(e);
  }
  return { list, flag_count };
}
