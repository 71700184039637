import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import Header from "../../../components/libs/Header";
import CustomerTable from "./customerTable";
import { downloadCustomers, loadFraudCustomers } from "../../../store/customer/action";
import { useDispatch } from "react-redux";
import CustomerModal from "./customerModal";
import { paymentMethodsGCC, paymentMethodsIN } from "../../../constants";
import CustomerUpload from "./customerUpload";
import SearchBox from "./searchBox";

const FraudCustomers = (...routeConfig) => {
  const region = localStorage.getItem("LOCATION");
   const paymentMethods =
     region === "IN" ? paymentMethodsIN : paymentMethodsGCC;
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const addNew = () => {
    setShowModal(true);
    setModalData({
      id: "",
      email: "",
      phone: "",
      blocked_payments: paymentMethods.map((m) => m.id),
    });
  };
  useEffect(() => {
    dispatch(loadFraudCustomers({page: page}));
  }, [dispatch,page]);

  const download = () =>{
    dispatch(downloadCustomers());
  }

  const openModal = () => (
    <CustomerModal
      open={showModal}
      closeModal={() => setShowModal(false)}
      data={modalData}
    />
  );

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={1}>
        <Grid item md={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <h1>Manage Fraud Customers</h1>
            </Grid>
            <Grid item md={2} xs={6} sm={2} lg={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={addNew}
                fullWidth
              >
                Add Customer
              </Button>
            </Grid>
            <Grid item md={3} xs={8} sm={3} lg={3}>
              <CustomerUpload />
            </Grid>
            <Grid item md={1} xs={4} sm={1} lg={1}>
              <Box
                p={1}
                flexGrow={1}
                style={{ cursor: "pointer" }}
                onClick={download}
              >
                <strong style={{ color: "green", "font-size": "12px" }}>
                  Download Template
                </strong>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <br />
        {showModal ? openModal() : <></>}
        <SearchBox />
        <CustomerTable page={page} setPage={setPage}/>
      </Box>
    </>
  );
};

export default FraudCustomers;
