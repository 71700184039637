export const baseUrl = (key) => {
  const region = localStorage.getItem("LOCATION");

  const url = process.env[key];
  if (url?.includes("localhost")) return url;
  if (region && region !== "GULF") {
    const [protcol, path] = url.split("://");
    return `${protcol}://${region.toLocaleLowerCase()}-${path}`;
  }
  return url;
};
