import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, Fab, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { criteriaMap } from "../../../constants";
import ExceptionListStyles from "../styleComponent";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

export default function Row({ row, sno, fraudRulesCols, editRow, deleteRow }) {
  const colCodes = Object.keys(fraudRulesCols);
  //   const [open, setOpen] = React.useState(false);
  const classes = ExceptionListStyles();

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell component="th" scope="row" align="left">
          {sno}
        </TableCell>
        {fraudRulesCols.map((el) => {
          return (
            <TableCell align="left" key={el.code}>
              {el.code === "criteria"
                ? criteriaMap.find((item) => item.code === row[el.code])?.label
                : row[el.code] && String(row[el.code])}
            </TableCell>
          );
        })}
        <TableCell component="th" scope="row" align="left">
          <Fab
            color="default"
            aria-label="edit"
            size="small"
            variant="extended"
            disableRipple={true}
            onClick={(e) => editRow(e, row)}
            className={classes.margin}
          >
            <EditIcon color="primary" />
          </Fab>
          <Fab
            color="default"
            aria-label="delete"
            size="small"
            variant="extended"
            disableRipple={true}
            onClick={(e) => deleteRow(e, row)}
            className={classes.margin}
          >
            <DeleteIcon color="secondary" />
          </Fab>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
