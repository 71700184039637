import React from "react";
import { Grid, Box } from "@material-ui/core";

import Header from "../../../components/libs/Header";

const RatingsDetail = (props, ...routeConfig) => {
  const queryParamsString = props.location.search.substring(1), // remove the "?" at the start
    searchParams = new URLSearchParams(queryParamsString);
  const orderId = searchParams.get("orderId");
  console.log("orderId " + orderId);
  const ratings = props.location.state.ratings;
  const orderRatings = ratings.filter((item) => item.orderId === orderId);

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={5}>
        <Grid item md={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={5}>
              <h1>Ratings Detail</h1>
              {orderRatings &&
                orderRatings.map((row, index) => {
                  if (row && row.rate) {
                    return (
                      <div>
                        <h3>
                          {" "}
                          Order Id : {row.orderId} ,{" "}
                          {row.type === "productRatings"
                            ? "Product Sku"
                            : "Delivery Ratings"}{" "}
                          {row.parentSku}{" "}
                        </h3>
                        <div> Rate : {row.rate} </div>
                        <div> Question : {row.questionId} </div>
                        <div>
                          {" "}
                          Options : {row.options && row.options.join(", ")}
                        </div>
                        <div> Comments : {row.comments} </div>
                      </div>
                    );
                  }
                })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RatingsDetail;
