import { useEffect, useState } from "react";
import CsvLib, { removeFile } from "../../../components/CsvLib";
import { uploadFraudCustomer } from "../../../store/customer/action";
import { useDispatch } from "react-redux";
import UploadSummary from "./uploadSummary";
import { Box } from "@material-ui/core";

const CustomerUpload = (props) => {
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const setCsvData = () => {
    return [];
  };
  useEffect(() => {
    if (file) {
      dispatch(uploadFraudCustomer(file));
      removeFile(file);
    }
  }, [file, dispatch]);

  return (
    <Box p={5}>
      <CsvLib setCsvData={setCsvData} setFile={setFile} /> <UploadSummary />
    </Box>
  );
};

export default CustomerUpload;
