import * as ActionTypes from "./actionTypes";

const initialState = {
  customers: [],
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_FRAUD_CUSTOMERS:
      return {
        ...state,
        customers: action.value.customers,
        total_customers: action.value.total_customers
      };
    case ActionTypes.UPDATE_FRAUD_CUSTOMERS:
      return state;
    case ActionTypes.UPLOAD_SUMMARY:
      return {
        ...state,
        upload_summary: action.value,
      };
    case ActionTypes.RESET_UPLOAD_SUMMARY:
      return {
        ...state,
        upload_summary: undefined,
      };
    default:
      return state;
  }
};

export default customerReducer;
