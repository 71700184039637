import React from 'react';
import Header from '../../components/libs/Header';

export default (...routeConfig) => {
  return (
    <>
      <Header routeConfig={routeConfig} />
      <h1>this is home</h1>
    </>
  );
};
