import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@material-ui/core";

import Header from "../../../components/libs/Header";
import EListTable from "./table";
import EListModal from "./exceptionModal";
import { exceptionListCols } from "../../../constants/index";
import { saveException, fetchEList } from "../actions";
import moment from "moment";

const ExceptionList = (...routeConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [eListData, setEListData] = useState([]);

  const upsertEList = async (exceptionData) => {
    console.log({ exceptionData });
    const { success } = await saveException({ exceptionData });

    if (success) {
      setTimeout(async () => {
        await getEList();
      }, 500);
      setShowModal(false);
    }
  };

  const getEList = async () => {
    fetchEList().then((res) => {
      console.log({ res });
      res.map((el) => {
        if (el.updated_at) el.updated_at = moment(el.updated_at).fromNow();
      });
      setEListData(res);
    });
  };

  const editRow = (e, rowData) => {
    setModalData(rowData);
    setShowModal(true);
  };

  useEffect(() => {
    getEList();
  }, []);

  return (
    <>
      <Header routeConfig={routeConfig} />
      <Box p={5}>
        <Grid item md={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={10}>
              <h1>Customer Execption List</h1>
            </Grid>
            <Grid item md={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={editRow}
                fullWidth
              >
                Add New Customer
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EListTable
          exceptionListCols={exceptionListCols}
          setShowModal={setShowModal}
          setModalData={setModalData}
          upsertEList={upsertEList}
          editRow={editRow}
          eListData={eListData}
        />
        {showModal ? (
          <EListModal
            modalData={modalData}
            showModal={showModal}
            upsertEList={upsertEList}
            setShowModal={setShowModal}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default ExceptionList;
