import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { loadFraudCustomers } from "../../../store/customer/action";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    border: "1px solid",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

export default function SearchBox() {
  const classes = useStyles();
  const [text, setText] = useState();
  const dispatch = useDispatch();

  const search = (e) => {
    e.preventDefault();
    if (text) {
      console.log('searchText',text);
      dispatch(loadFraudCustomers({searchFor:text}));
    }else{
      dispatch(loadFraudCustomers());
    }
  };
  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search Fraud Customer by email/phone"
        inputProps={{ "aria-label": "search fraud customer" }}
        onChange={(e) => setText(e.target.value)}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={search}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
