import axios from '../../../utils/axios';
import { isLoggedOut, logError, path } from '../../../utils/index';
import { addNotification } from '../../../components/libs/Notifications';
import { baseUrl } from '../../../utils/hosts';

export async function submitBulkUpdate({ file }) {
  const notificationProps = {
    title: `Bulk message`,
    message: '',
    success: false,
  };
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
      `${baseUrl('REACT_APP_API_URL')}/bulkWalletUpdate`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Type': file.type
        },
      }
    );
    const success = path(['data', 'status'], response);
    const statusMsg = path(['data', 'statusMsg'], response);

    notificationProps.message = success
      ? `Bulk update requested successfully!`
      : `${statusMsg}`;
    notificationProps.success = success;
    addNotification(notificationProps);
    return success;
  } catch (e) {
    notificationProps.message = `Error sending bulk update : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    if (e.response?.status === 403)
      notificationProps.message = `Error sending bulk update: Forbidden! Please ask admin for permissions!`;
    addNotification(notificationProps);
    logError(e);
    // return { success: false }
    return false;
  }
}
