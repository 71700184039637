import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import { withStyles } from "@material-ui/core/styles";
import csvPreViewStyle from "../styleComponent";

const BlankCell = withStyles((theme) => ({
  root: {
    backgroundColor: "red",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

export default ({ csvData = [], setHasError = () => {}, colError = false }) => {
  const classes = csvPreViewStyle();

  const [headData, ...bodyData] = csvData;
  //   console.log({ headData, bodyData });

  const tableData =
    headData && headData.data && headData.data.length ? (
      <>
        <Grid item md={12}>
          <h1>CSV preview</h1>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headCell} align="left">
                  S No.
                </TableCell>
                {headData &&
                  headData.data &&
                  headData.data.map((element, index) => {
                    return (
                      <TableCell
                        className={`${classes.headCell} ${
                          colError ? classes.errorCol : ""
                        }`}
                        align="left"
                        key={index}
                      >
                        {element}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {bodyData &&
                bodyData.map((element, index) => {
                  return (
                    <StyledTableRow>
                      <TableCell align="left" key={index}>
                        {index + 1}
                      </TableCell>
                      {element.data.map((item, rowIndex) => {
                        if (!item) setHasError(true);
                        let cell;
                        let isError = false;
                        if (item) {
                          if (rowIndex === 1 && !["0", "1"].includes(item)) {
                            isError = true;
                          }
                        } else {
                          isError = true;
                        }
                        return (
                          <TableCell
                            className={isError ? classes.errorCol : ""}
                            align="left"
                            key={`${index}${item}`}
                          >
                            {item}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      ""
    );
  //   console.log({ tableData });
  return tableData;
};
