import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import Row from "./tableRow";
const useRowStyles = makeStyles((theme) => ({
    root: {},
    headCell: {
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[400],
    },
}));

const RatingsTable = ({ ratingsCols = [], ratingsList = [] }) => {
    const classes = useRowStyles();
    const pages = [5, 10, 25]
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0);
    }

    const recordsAfterPaging = () => {
        const filteredRatings = [...new Map(ratingsList.map(item =>
            [item['orderId'], item])).values()];
        return filteredRatings.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }

    return (
        <Paper className={classes.pageContent}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell} align="left">
                                S No.
                            </TableCell>
                            {ratingsCols.map((element, index) => {
                                return (
                                    <TableCell
                                        className={classes.headCell}
                                        align="left"
                                        key={index}
                                    >
                                        {element.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ratingsList &&
                            recordsAfterPaging().map((row, index) => {
                                const { input1, input2 } = row;
                                console.log('hi ' + row.orderId);
                                return (
                                    <Row
                                        orderId={row.orderId}
                                        sno={index + 1}
                                        row={{
                                            ...row,
                                            input: `${input1} ${input2 ? `, ${input2}` : ""}`,
                                        }}
                                        ratingsCols={ratingsCols}
                                        ratingsList={ratingsList}
                                    />
                                );
                            })}
                    </TableBody>
                </Table>

            </TableContainer>
            <TablePagination
                component="div"
                page={page}
                rowsPerPageOptions={pages}
                rowsPerPage={rowsPerPage}
                count={ratingsList.length}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default RatingsTable;
