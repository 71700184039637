import axios from "../../../utils/axios";
import { isLoggedOut, logError, path } from "../../../utils/index";
import { addNotification } from "../../../components/libs/Notifications";
import fileDownload from "js-file-download";
import { baseUrl } from "../../../utils/hosts";

export async function fetchActivityLogs({ fromDate, toDate, isExport, page }) {
  const notificationProps = {
    title: `Fetch Activity Logs`,
    message: "",
    success: false,
  };
  let list = [];
  let activity_count = 0;

  try {
    const limit = 100;
    const requestBody = { fromDate: fromDate, toDate: toDate, isExport, page };
    const fetchResponse = await axios.post(
      `${baseUrl("REACT_APP_API_URL")}/getActivityLogs`,
      requestBody,
      {
        responseType: !isExport ? undefined : "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: isExport ? "text/csv" : "application/json",
        },
      }
    );

    if (!isExport) {
      const success = path(["data", "status"], fetchResponse);
      const statusMsg = path(["data", "statusMsg"], fetchResponse);
      list = path(["data", "response", "activityLogs", "rules"], fetchResponse);
      activity_count = path(["data", "response", "activityLogs", "activity_count"], fetchResponse);

      notificationProps.message = success
        ? "Activity Logs Fetched successfully!"
        : `${statusMsg}`;
      notificationProps.success = success;
      addNotification(notificationProps);
    } else {
      fileDownload(fetchResponse.data.activityLogs.rules, "activityLogs.csv");
    }
  } catch (e) {
    notificationProps.message = `Error fetching Activity Logs : ${e.message}`;
    // if (isLoggedOut(e)) {
    //   notificationProps.title = `Logged Out`;
    //   notificationProps.message = `Please login again`;
    // }
    if(e.response?.status === 403){
      notificationProps.message = 'You do not have adequate permission to access this Page. Please contact your administrator.';
    }
    addNotification(notificationProps);
    logError(e);
  }
  return { list, activity_count };
}
