const subdomain = window.location.hostname;
const subdomainParts = subdomain.split('.');
const domainName = subdomainParts.slice(-2).join('.');
console.log('domainName',domainName);


export const isLoggedOut = (e) => {
  if (e.response && e.response.status === 401) {
    localStorage.setItem('styli_sso_user', '');
    return true;
  }
  return false;
};


export const getCurrentUser = () => {
  let user = null;
  try {
    user = getJsonCookie('styli_sso_user', domainName);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  console.log('cookieee::',user);
  return user;
};

export const setCurrentUser = user => {
  const cookieName = 'styli_sso_user';
  try {
    if (user) {
      setJsonCookie(cookieName, user,0,domainName);
      // localStorage.setItem('styli_sso_user', JSON.stringify(user));
    } else {
      // localStorage.removeItem('styli_sso_user');
      document.cookie = `${cookieName}=; domain=${domainName}; path=/;`;
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const removeFromCookie = () => {
  const cookieName = 'styli_sso_user';
  document.cookie = `${cookieName}=; domain=${domainName}; path=/;`;
};
const getJsonCookie = (cookieName, domain) => {
  const cookieValue = getCookie(cookieName,domain);
  console.log('cookieValue',cookieValue);
  if (cookieValue) {
    try {
      // Parse the JSON value from the cookie
      return JSON.parse(decodeURIComponent(cookieValue));
    } catch (error) {
      // Handle parsing errors if necessary
      console.error('Error parsing JSON from cookie:', error);
    }
  }

  return null; // Return null if the cookie or JSON value doesn't exist
};
const getCookie = (cookieName, domain) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    // Check if the cookie starts with the specified name
    if (cookie.indexOf(cookieName + '=') === 0) {
      // Check if the cookie domain matches the specified domain
      if (domain && cookie.indexOf('domain=' + domain) === -1) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  }
  return null;
};

const setJsonCookie = (cookieName, jsonObject, expirationDays, domain) => {
  const jsonString = JSON.stringify(jsonObject);

  let cookieString = `${cookieName}=${encodeURIComponent(jsonString)}`;

  if (expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    cookieString += `; expires=${expirationDate.toUTCString()}`;
  }

  if (domain) {
    cookieString += `; domain=${domain}`;
  }

  document.cookie = cookieString;
};