import firebase from 'firebase/app';
import 'firebase/auth';

const devConfig = {
  apiKey: 'AIzaSyBXprkSsdWnA8Ks9HJ7XAjAvt9bcO_iAkQ',
  authDomain: 'stylishopdev.firebaseapp.com',
  projectId: 'stylishopdev',
  storageBucket: 'stylishopdev.appspot.com',
  messagingSenderId: '640935596576',
  appId: '1:640935596576:web:393a09830cb8b64e672245',
  measurementId: 'G-WDEYHW4RET',
};

const prodConfig = {
  apiKey: 'AIzaSyBE5jksNFb01168sKeTRjgfInnBQNDtUXU',
  authDomain: 'stylishopprod.firebaseapp.com',
  projectId: 'stylishopprod',
  storageBucket: 'stylishopprod.appspot.com',
  messagingSenderId: '476895285940',
  appId: '1:476895285940:web:3897b49635753e08ba3de1',
  measurementId: 'G-5VP371TRY8',
};

if (process.env.REACT_APP_FIREBASE_ENV === 'prod')
  firebase.initializeApp(prodConfig);
else firebase.initializeApp(devConfig);

export const googleAuthProvider = {
  googleProvider: new firebase.auth.GoogleAuthProvider().setCustomParameters({
    hd: 'stylishop.com',
  }),
};

googleAuthProvider.googleProvider.setCustomParameters({
  hd: 'stylishop.com',
});

//firebaseApp.auth();
export const auth = firebase.auth();
