import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  InputLabel,
  Select,
  FormControl,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { criteriaMap, operatorMap, penaltyMap } from "../../../constants/index";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputCenter: {
    textAlign: "center",
  },
}));

const validationSchema = Yup.object({
  name: Yup.string().required("Rule Name is required!"),
  criteria: Yup.string().required("Criteria required"),
  operator: Yup.string().required("Condition required"),
  output: Yup.string().required("Penalty required"),
  enabled: Yup.boolean().required("Status required"),
  input1: Yup.number().required("Required field!"),
  input2: Yup.number(),
});

const RuleModal = (props) => {
  const { modalData = {}, upsertRule, showModal = false, setShowModal } = props;
  const { id, name, criteria, enabled, operator, input1, input2, output } =
    modalData;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const formik = useFormik({
    initialValues: {
      id,
      name,
      criteria: criteria || criteriaMap[0].code,
      enabled: enabled === undefined ? false : enabled,
      operator: operator || operatorMap[0].code,
      input1,
      input2,
      output: output || penaltyMap[0].code,
      // tier
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      upsertRule(values);
    },
  });

  const { values: formikVals } = formik;

  const selectedOperator = operatorMap.find(
    (el) => el.code === formikVals.operator
  );

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {/* <h1>Rule modal heading</h1> */}
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid item md={12}>
              <Grid container spacing={3} alignItems="center">
                <Grid item md={5}>
                  <TextField
                    id="id"
                    name="id"
                    type="hidden"
                    value={formikVals.id || -1}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Rule Name"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="output">Penalty</InputLabel>
                    <Select
                      native
                      value={formik.values.output}
                      onChange={formik.handleChange}
                      inputProps={{
                        name: "output",
                        id: "output",
                      }}
                      className={classes.inputCenter}
                    >
                      {/* <option aria-label="None" value="" /> */}
                      {penaltyMap.map((el) => {
                        return <option value={el.code}>{el.label}</option>;
                      })}
                    </Select>
                    {/* <FormHelperText>Some important helper text</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.enabled}
                        name="enabled"
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label="Enabled"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={3}></Grid>
            </Grid>

            <Grid item md={12}>
              <Grid container spacing={3} display="flex" alignItems="center">
                <Grid item md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="criteria">Criteria</InputLabel>
                    <Select
                      native
                      value={formik.values.criteria}
                      onChange={formik.handleChange}
                      inputProps={{
                        name: "criteria",
                        id: "criteria",
                      }}
                      className={classes.inputCenter}
                    >
                      {/* <option aria-label="None" value="" /> */}
                      {criteriaMap.map((el) => {
                        return <option value={el.code}>{el.label}</option>;
                      })}
                    </Select>
                    {/* <FormHelperText>Some important helper text</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="operator">Condition</InputLabel>
                    <Select
                      native
                      value={formik.values.operator}
                      onChange={formik.handleChange}
                      inputProps={{
                        name: "operator",
                        id: "operator",
                      }}
                      className={classes.inputCenter}
                    >
                      {/* <option aria-label="None" value="" /> */}
                      {operatorMap.map((el) => {
                        return (
                          <option
                            className={classes.inputCenter}
                            value={el.code}
                          >
                            {el.label || el.code}
                          </option>
                        );
                      })}
                    </Select>
                    {/* <FormHelperText>Some important helper text</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      id="input1"
                      name="input1"
                      label="Input One"
                      type="number"
                      value={formik.values.input1}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.input1 && Boolean(formik.errors.input1)
                      }
                      helperText={formik.touched.input1 && formik.errors.input1}
                    />
                  </FormControl>
                </Grid>
                {selectedOperator && selectedOperator.numOfOperand === 2 ? (
                  <Grid item md={2}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      id="input2"
                      name="input2"
                      label="Input Two"
                      type="number"
                      value={formik.values.input2}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.input2 && Boolean(formik.errors.input2)
                      }
                      helperText={formik.touched.input2 && formik.errors.input2}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={12}></Grid>
                <Grid item md={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item md={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid
                container
                spacing={3}
                display="flex"
                alignItems="center"
              ></Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default RuleModal;
