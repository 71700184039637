import React, { useEffect, useState } from "react";
import {
  Fab,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { resetUploadSummary } from "../../../store/customer/action";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    // maxHeight: "90%",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useRowStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  headCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[400],
  },
  table: {
    maxHeight: "500px",
  },
  closeIcon: {
    float: "right",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}))(TableRow);

const UploadSummary = () => {
  const classes = useRowStyles();
  const [openSummary, setOpenSummary] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const upload_summary = useSelector((state) => state.fraud.upload_summary);
  const dispatch = useDispatch();

  useEffect(() => {
    if (upload_summary) {
      setOpenSummary(true);
    }
  }, [upload_summary]);

  const closeSummaryModal = () => {
    setOpenSummary(false);
    dispatch(resetUploadSummary());
  }

  return (
    <Modal
      open={openSummary}
      onClose={closeSummaryModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Fab
          color="primary"
          size="small"
          onClick={closeSummaryModal}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </Fab>
        <Typography variant="subtitle2">
          <strong>Upload Summary</strong>
        </Typography>
        <Grid container justify="center" spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography variant="subtitle2">
              Total Record Uploaded : {upload_summary?.total}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="subtitle2" color="error">
              Failed Record : {upload_summary?.failed}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle2">
          <strong>Failed Record Details</strong>
        </Typography>
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headCell} align="left">
                  Row No.
                </TableCell>
                <TableCell className={classes.headCell} align="left">
                  Email
                </TableCell>
                <TableCell className={classes.headCell} align="left">
                  Phone
                </TableCell>
                <TableCell className={classes.headCell} align="left">
                  Error Message
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {upload_summary &&
                upload_summary?.failures?.map((row, index) => {
                  return (
                    <StyledTableRow className={classes.root} key={index}>
                      <TableCell align="left">{row.data.index}</TableCell>
                      <TableCell align="left">{row.data.email}</TableCell>
                      <TableCell align="left">{row.data.phone}</TableCell>
                      <TableCell align="left">{row.message}</TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Modal>
  );
};

export default UploadSummary;
