import React from 'react';
import Helmet from 'react-helmet';

import Notifications from './components/libs/Notifications';

export default ({ children }) => {
  // const [route] = routeConfig;
  // console.log(routeConfig);
  return (
    <>
      <Helmet>
        <title>Styli Customer Management</title>
      </Helmet>
      {/* <h1>this is strat up</h1> */}
      <Notifications />
      {children}
    </>
  );
};
