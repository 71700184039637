import { makeStyles } from '@material-ui/core/styles';
const csvPreViewStyle = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  headCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[400]
  },
  errorCol: {
    backgroundColor: 'red',
    border: '2px solid'
  }
  
}));

const ExceptionListStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  margin: {
    margin: theme.spacing(1)
  }
}));
const msgHistoryStyle = makeStyles(theme => ({
  headCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[400]
  },
}));
const walletHistoryStyles = makeStyles(theme => ({
  root: {},
  headCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[400]
  }
}));
export default csvPreViewStyle;
export {
  ExceptionListStyles,
  msgHistoryStyle,
  walletHistoryStyles
}